import React, { useState, useEffect } from 'react';
import SavingsSummary from './SavingsSummary';
import { appAxios } from 'api/axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'components/shared/input-field';
import SelectInput from 'components/shared/select-input';
import Select from 'react-select';
import styles from './FinishSavingsTarget.module.css';
import Button from 'components/shared/button';
import { formatToNaira } from 'functions/currencyConveter';
import { OptionType } from '../../../../types';
import { useNavigate } from 'react-router-dom';
import CardFormModal from 'components/shared/modal/CardForm';
import Checkbox from 'components/shared/checkbox/index';

interface FinishSavingsProps {
  frequency?: string;
  monthlyContribution?: string;
  targetAmount?: string;
  startDate?: string;
  endDate?: string;
  preferredTime?: string;
  autoSave?: boolean;
  autoWithdrawal?: boolean;
  status?: string;
  savingSource?: string;
  interestTagentSaving?: boolean;
  targetBreakBeforeEndDate?: boolean;
  savingsTime?: string;
  cardDetails?: {
    cardNumber: string;
    expiryMonth: string;
    expiry_year: string;
    cvv: string;
  };
}
interface FinishSavingsTargetProps {
  handleBack: () => void;
  handleNext: () => void;
  handleDataSubmit: () => void;
  formValues: FinishSavingsProps;
  setFormValues: (data: FinishSavingsProps) => void;
}

const validationSchema = yup.object({
  amount: yup
    .number()
    .required('Preferred amount is required')
    .typeError('Must be a number')
    .positive('Amount must be positive'),
  targetAmount: yup.string().required('Goal amount is required'),
  // preferredTime: yup
  //   .string()
  //   .required('Preferred time is required')
  //   .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format'),
  startDate: yup.date().required('Start date is required'),
  endDate: yup
    .date()
    .required('End date is required')
    .min(yup.ref('startDate'), 'End date must be after start date'),
  autoSave: yup.boolean(),
  savingSource: yup.string().required('Saving source is required'),
});

function FinishSavingsTarget({
  handleBack,
  handleNext,
  handleDataSubmit,
  formValues,
  setFormValues,
}: FinishSavingsTargetProps) {
  const [dropdownOptions, setDropdownOptions] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [sourceOptions, setSourceOptions] = useState<OptionType[]>([]);
  const [isCardModalOpen, setCardModalOpen] = useState(false);
  const [isAuthorizeDebitChecked, setIsAuthorizeDebitChecked] = useState(false);
  const [isInterestTargetChecked, setIsInterestTargetChecked] = useState(false);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit: (values) => {
      const dataToSubmit = {
        ...values,
        savingsTime: values.preferredTime,
      };
      setFormValues(dataToSubmit);
    },
  });

  const handleCardDetailsSave = (cardDetails: any) => {
    // Update Formik's cardDetails
    formik.setFieldValue('cardDetails', cardDetails);
    setCardModalOpen(false); // Close the modal
  };

  const saveOptions: OptionType[] = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ];

  useEffect(() => {
    const getPaymentSource = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(`/get-saving-source`);
        if (data && data.statusCode === 200) {
          const formattedOptions = data.data.map((source: any) => ({
            label: source.name,
            value: source.id,
          }));
          setSourceOptions(formattedOptions);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getPaymentSource();
  }, []);

  const formatWithCommas = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { name, value } = e.target;

    const plainValue = value.replace(/,/g, '');
    if (name === `monthlyContribution` && index !== undefined) {
      const formattedValue = formatWithCommas(plainValue);
      formik.setFieldValue(`monthlyContribution`, formattedValue);
      formik.handleChange(e);
    } else if (name === `targetAmount` && index !== undefined) {
      const formattedValue = formatWithCommas(plainValue);
      formik.setFieldValue(`targetAmount`, formattedValue);
      formik.handleChange(e);
    } else {
      formik.handleChange(e);
    }

    // clearErrors(name);
  };

  const getContributionLabel = () => {
    switch (formik.values.frequency) {
      case 'daily':
        return 'Preferred amount to save on a daily basis';
      case 'weekly':
        return 'Preferred amount to save on a weekly basis';
      case 'monthly':
        return 'Preferred amount to save on a monthly basis';
      default:
        return 'Preferred amount to save';
    }
  };

  useEffect(() => {
    if (
      formik.values.startDate &&
      formik.values.frequency &&
      formik.values.targetAmount &&
      formik.values.monthlyContribution
    ) {
      const startDate = new Date(formik.values.startDate);
      const targetAmount = parseFloat(
        formik.values.targetAmount.replace(/,/g, '')
      );
      const contribution = parseFloat(
        formik.values.monthlyContribution.replace(/,/g, '')
      );

      let endDate = new Date(startDate);

      if (formik.values.frequency === 'daily') {
        const daysRequired = Math.ceil(targetAmount / contribution);
        endDate.setDate(startDate.getDate() + daysRequired);
      } else if (formik.values.frequency === 'weekly') {
        const weeksRequired = Math.ceil(targetAmount / contribution);
        endDate.setDate(startDate.getDate() + weeksRequired * 7);
      } else if (formik.values.frequency === 'monthly') {
        const monthsRequired = Math.ceil(targetAmount / contribution);
        endDate.setMonth(startDate.getMonth() + monthsRequired);
      }

      formik.setFieldValue('endDate', endDate.toISOString().split('T')[0]);
    }
  }, [
    formik.values.startDate,
    formik.values.frequency,
    formik.values.targetAmount,
    formik.values.monthlyContribution,
  ]);

  useEffect(() => {
    if (formik.values.savingSource === 'Card') {
      setCardModalOpen(true);
    }
  }, [formik.values.savingSource]);

  useEffect(() => {
    setFormValues(formik.values);
  }, [formik.values]);

  const targetAmount = parseFloat(
    formValues?.targetAmount?.toString().replace(/,/g, '') || '0'
  );

  return (
    <section>
      <div className="flex flex-col gap-y-[10px] mb-[30px]">
        <div className="flex flex-col lg:flex-row justify-between items-center ">
          <div className="mb-4 lg:mb-0">
            <h1 className="font-semibold">
              Create a JompReserve Target Savings.
            </h1>
            <p>
              Set up a new savings target and get paid everyday (@ 12% interest
              P.A) to reach your goals faster.
            </p>
          </div>
        </div>
      </div>
      {/* ********************************** */}
      <div className="flex flex-col-reverse gap-y-[20px] lg:flex-row lg:gap-x-[50px]">
        <main className=" flex-1 bg-white rounded-md px-[15px] py-[20px] lg:w-[60%]">
          <header className="flex flex-col gap-y-[3px] mb-[20px]">
            <h1 className="font-semibold text-[20px]">Finish Setting Up</h1>
            <p>Finalize your target settings</p>
            <div className="h-[1px] bg-gray-3 mt-[10px] mb-[25px]"></div>
          </header>
          <form action="" className="flex flex-col gap-y-[20px]">
            <div className="flex flex-col gap-y-[2px]">
              <label htmlFor="autoSave">
                Do you want to enable auto savings?
              </label>

              <div className="flex gap-3">
                <div
                  className={`flex items-center cursor-pointer w-[40px] h-[20px] rounded-full transition-all 
                  ${formik.values.autoSave ? 'bg-[#EFA005]' : 'bg-gray-300'}`}
                  onClick={() =>
                    formik.setFieldValue('autoSave', !formik.values.autoSave)
                  }
                >
                  <div
                    className={`w-[25px] h-[25px] bg-white rounded-full shadow-md transform transition-all 
                    ${formik.values.autoSave ? 'translate-x-[15px]' : 'translate-x-0'}`}
                  />
                </div>
                <p className="text-sm mt-1">
                  {formik.values.autoSave ? 'Yes' : 'No'}
                </p>
              </div>
            </div>

            <div>
              {formik.values.autoSave && (
                <InputField
                  label="Preferred Time"
                  type="time"
                  name="preferredTime"
                  placeholder="e.g 4:30 am"
                  value={formik.values.preferredTime || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.preferredTime}
                />
              )}
            </div>

            <SelectInput
              label="Select a source of funding"
              name="savingSource"
              options={sourceOptions}
              placeholder="Select source"
              value={
                sourceOptions.find(
                  (option) => option.label === formik.values.savingSource
                ) || null
              }
              onChange={(name, value) =>
                formik.setFieldValue(name, value?.label)
              }
              required
              error={formik.errors.savingSource}
            />

            <div className="flex flex-col gap-y-[2px]">
              <label htmlFor="autoSave">
                Do you want to enable auto withdrawal at the end of your savings
                duration?
              </label>

              <div className="flex gap-3">
                <div
                  className={`flex items-center cursor-pointer w-[40px] h-[20px] rounded-full transition-all 
                  ${formik.values.autoWithdrawal ? 'bg-[#EFA005]' : 'bg-gray-300'}`}
                  onClick={() =>
                    formik.setFieldValue(
                      'autoWithdrawal',
                      !formik.values.autoWithdrawal
                    )
                  }
                >
                  <div
                    className={`w-[25px] h-[25px] bg-white rounded-full shadow-md transform transition-all 
                    ${formik.values.autoWithdrawal ? 'translate-x-[15px]' : 'translate-x-0'}`}
                  />
                </div>
                <p className="text-sm mt-1">
                  {formik.values.autoWithdrawal ? 'Yes' : 'No'}
                </p>
              </div>
            </div>

            {/* <hr /> */}
            {/* Agreements */}
            <div className="flex flex-col space-y-2">
              <Checkbox
                id="interestTagentSaving"
                // label="I hereby agree that I will forfeit the interest accrued on
                //   this target savings if I fail to meet this target amount of (₦
                //   {formValues.targetAmount}) by the end of the savings
                //   duration."
                label={`I hereby agree that I will forfeit the interest accrued on this target savings if I fail to meet this target amount of (${formatToNaira(targetAmount) || 0}) by the end of the savings duration.`}
                checked={isInterestTargetChecked}
                onChange={(e) => {
                  const checked = (e.target as HTMLInputElement).checked;
                  setIsInterestTargetChecked(checked);
                  formik.setFieldValue('interestTagentSaving', checked);
                }}
                className="mb-4 text-sm"
              />
              <Checkbox
                id="targetBreakBeforeEndDate"
                label={`I hereby agree to this: "If I break this target before the end of the savings duration, I will lose all the interest accrued."`}
                checked={isAuthorizeDebitChecked}
                onChange={(e) => {
                  const checked = (e.target as HTMLInputElement).checked;
                  setIsAuthorizeDebitChecked(checked);
                  formik.setFieldValue('targetBreakBeforeEndDate', checked);
                }}
                className="mb-4 text-sm"
              />
            </div>

            <div className="h-[1px] bg-gray-3 mt-[10px] mb-[25px]"></div>

            <div className="flex justify-end gap-x-[20px]">
              <button
                onClick={handleBack}
                className="border border-[#EFA005] px-12 py-2 rounded-[8px] outline-none focus:shadow-md text-[#EFA005]"
              >
                Back
              </button>
              <Button
                type="button"
                className="outline-none"
                onClick={handleNext}
              >
                Create Savings
              </Button>
            </div>
          </form>
        </main>

        <SavingsSummary formValues={formValues} />
      </div>

      {/* Render the CardFormModal */}
      {isCardModalOpen && (
        <CardFormModal
          isOpen={isCardModalOpen}
          onClose={() => setCardModalOpen(false)}
          onSave={handleCardDetailsSave}
        />
      )}
    </section>
  );
}

export default FinishSavingsTarget;
