import React, { useState } from 'react';

interface InputFieldProps {
  label: string;
  options: number[];
  onChange: (value: number) => void;
  required?: boolean;
}

const InputAmountWithTags: React.FC<InputFieldProps> = ({
  label,
  options,
  onChange,
  required = false,
}) => {
  const [amount, setAmount] = useState<string>('');

  const handleSelect = (value: number) => {
    setAmount(value.toString());
    onChange(value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
    setAmount(value);
    onChange(Number(value) || 0);
  };

  return (
    <div className="w-full">
      {/* Label */}
      <label className="text-gray-600 text-md">
        {label} {required && <span className="text-red-500">*</span>}
      </label>

      {/* Input Field */}
      <input
        type="text"
        value={amount ? `₦ ${Number(amount).toLocaleString()}` : ''}
        onChange={handleInputChange}
        placeholder="₦ 0.00"
        className="w-full mt-1 bg-transparent text-gray-500 text-lg p-2 rounded-md border border-gray-300 focus:ring-1 "
      />

      {/* Selectable Amounts */}
      <div className="flex gap-3 mt-3">
        {options.map((value) => (
          <button
            key={value}
            className={`p-1 rounded-md text-gray-700 bg-gray-200 border border-gray-200 
              ${Number(amount) === value ? 'bg-gray-300 text-gray-900' : 'hover:bg-gray-200'}`}
            onClick={() => handleSelect(value)}
          >
            ₦ {value.toLocaleString()}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InputAmountWithTags;
