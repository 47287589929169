import React, { useState } from 'react';
import { Table, Tabs, Progress } from 'antd';
import type { TabsProps } from 'antd';
import NoSavingsYet from '../../pages/user/dashboard/savings/NoSavingsYet';
import type { ColumnsType } from 'antd/es/table';
import { formatToNaira } from 'functions/currencyConveter';
import SavingsTabModal from 'components/shared/modal/SavingsTabModal';
import WithdrawSavingsPopUs from 'pages/user/dashboard/savings/WithdrawSavingsPopUs';
import { sendCatchFeedback, sendFeedback } from 'functions/feedback';

// Define the table data structure
interface SavingsData {
  key: string;
  name: string;
  maturityDate: string;
  savingsGoal: string;
  goalLevel: string;
}

interface SavingsItem {
  id: string;
  goalName: string;
  targetAmount: number;
  savedAmount: number;
  startDate: string;
  endDate: string;
  status: string;
  duration: number;
  isAchieved: boolean;
  createdAt: string;
  imageUrl: string;
}

interface SavedItemsProps {
  allSavings: SavingsItem[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const SavingsTabs: React.FC<SavedItemsProps> = ({
  allSavings,
  activeTab,
  setActiveTab,
}) => {
  // State to track active tab
  // const [activeTab, setActiveTab] = useState('ongoing');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSavings, setSelectedSavings] = useState<SavingsItem | null>(
    null
  );
  const [isWithdrawPopupOpen, setIsWithdrawPopupOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Adjust as needed

  const handleOpenModal = (item: SavingsItem) => {
    setSelectedSavings(item);
    setIsModalOpen(true);
  };
  // Sample data for each tab

  // Define columns for the table
  const columns: ColumnsType<SavingsItem> = [
    { title: 'Savings Name', dataIndex: 'goalName', key: 'goalName' },
    {
      title: 'Maturity Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate: string) =>
        new Date(endDate).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }),
      // render: (endDate: string) => new Date(endDate).toLocaleString(),
    },
    {
      title: 'Target Amount',
      dataIndex: 'targetAmount',
      key: 'targetAmount',
      render: (targetAmount: number) => (
        <p className="">{formatToNaira(targetAmount)}</p>
      ),
    },
    {
      title: 'Saved Amount',
      dataIndex: 'savedAmount',
      key: 'savedAmount',
      render: (savedAmount: number) => (
        <p className="">{formatToNaira(savedAmount)}</p>
      ),
    },
    {
      title: 'Goal Level',
      dataIndex: 'goalLevel',
      key: 'goalLevel',
      render: (_, record) => (
        <Progress
          percent={(record.savedAmount / record.targetAmount) * 100}
          status="active"
          showInfo={true}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <a
          className="text-[#EFA005] font-medium hover:underline cursor-pointer"
          onClick={() => handleOpenModal(record)}
        >
          View Details
        </a>
      ),
    },
  ];

  // Pagination Logic
  const totalPages = Math.ceil(allSavings.length / itemsPerPage);
  console.log(selectedSavings, 'selectedSavings');
  // const displayedSavings = allSavings.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  // const tabs = [
  //   { key: 'ongoing', label: 'Ongoing', status: 'Ongoing' },
  //   { key: 'rolledOver', label: 'Rolled-over', status: 'Rolled Over' },
  //   { key: 'completed', label: 'Completed', status: 'Completed' },
  // ];
  const tabs = [
    { key: 'ongoing', label: 'Ongoing', status: 'Active' },
    { key: 'rolledOver', label: 'Rolled-over', status: 'Complete' },
    { key: 'completed', label: 'Completed', status: 'Done' },
  ];

  const filteredSavings = allSavings.filter(
    (item) => item.status === tabs.find((tab) => tab.key === activeTab)?.status
  );

  const displayedSavings = filteredSavings.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const currentStatus = tabs.find((tab) => tab.key === activeTab)?.status || '';

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSavings(null);
  };

  const handleWithdraw = () => {
    setIsModalOpen(false);
    setSelectedSavings(null);
  };

  return (
    <div className="p-6 bg-white rounded-lg mt-6 shadow-md">
      {/* Tabs Navigation */}
      <div className="flex border-b-4 border-gray-300">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            className={`px-6 py-3 text-lg font-medium focus:outline-0 transition-all 
              ${
                activeTab === tab.key
                  ? 'border-b-4 border-[#31005C] text-[#31005C]'
                  : 'text-gray-500 hover:text-gray-800'
              }`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      {activeTab === 'ongoing' && allSavings.length > 0 ? (
        // <div className="mt-4">
        <Table
          // columns={columns}
          // dataSource={tabs.find((tab) => tab.key === activeTab)?.data}
          // pagination={false}
          columns={columns}
          dataSource={displayedSavings}
          pagination={false}
          rowKey="id"
        />
      ) : (
        // {/* </div> */}
        <NoSavingsYet />
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <div className="flex gap-2">
          <button
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-[#EFA005] text-white rounded disabled:opacity-50 disabled:bg-gray-200 disabled:text-gray-600"
            disabled={currentPage === totalPages || totalPages === 0}
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
          >
            Next
          </button>
        </div>

        <span className="text-gray-600">
          Page {totalPages > 0 ? currentPage : 0} of {totalPages}
        </span>
      </div>
      {/* <div className="flex justify-between items-center mt-4">
        <div className="flex gap-2">
          <button
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-[#EFA005] text-white rounded disabled:opacity-50 disabled:bg-gray-200 disabled:text-gray-600"
            disabled={
              currentPage === totalPages ||
              totalPages === 0 ||
              tabs.find((tab) => tab.key === activeTab)?.data.length === 0
            }
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
          >
            Next
          </button>
        </div>

        <span className="text-gray-600">
          Page {totalPages > 0 ? currentPage : 0} of {totalPages}
        </span>
      </div> */}
      {selectedSavings && (
        <SavingsTabModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={selectedSavings.goalName}
          amount={formatToNaira(selectedSavings.targetAmount)}
          status={currentStatus}
          details={[
            {
              label: 'Start Date',
              value: new Date(selectedSavings.startDate).toLocaleDateString(
                'en-GB',
                { day: '2-digit', month: 'short', year: 'numeric' }
              ),
            },
            {
              label: 'Maturity Date',
              value: new Date(selectedSavings.endDate).toLocaleDateString(
                'en-GB',
                { day: '2-digit', month: 'short', year: 'numeric' }
              ),
            },
            { label: 'Duration', value: selectedSavings.duration.toString() },

            {
              label: 'Saved Amount',
              value: formatToNaira(selectedSavings.savedAmount),
            },
          ]}
          primaryAction={{
            text: 'Withdraw',
            onClick: () => {
              if (selectedSavings && selectedSavings.savedAmount > 0) {
                setIsModalOpen(false);
                setIsWithdrawPopupOpen(true);
              } else {
                sendFeedback(
                  'You cannot withdraw because there are no savings available.',
                  'error'
                );
              }
            },
            // onClick: () => {
            //   setIsModalOpen(false);
            //   setIsWithdrawPopupOpen(true);
            // },
            // onClick: () => setIsModalOpen(false),
          }}
          secondaryAction={{
            text: 'Cancel',
            onClick: () => setIsModalOpen(false),
          }}
        />
      )}

      {isWithdrawPopupOpen && selectedSavings && (
        <WithdrawSavingsPopUs
          isOpen={isWithdrawPopupOpen}
          onClose={() => setIsWithdrawPopupOpen(false)}
          id={selectedSavings?.id}
        />
      )}
    </div>
  );
};

export default SavingsTabs;
