import React, { useState, useEffect } from 'react';
import SavingsSummary from './SavingsSummary';
import Button from 'components/shared/button';
import styles from './BeginSavingsTarget.module.css';
import { appAxios } from 'api/axios';
import Select from 'react-select';
import SelectInput from 'components/shared/select-input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { IoAlertCircle } from 'react-icons/io5';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputField from 'components/shared/input-field';
import Input from 'components/shared/input-field/inputAmountTags';
import { OptionType, SavingsFormData } from '../../../../types';

type BeginSavingsProps = {
  goalName: string;
  targetAmount?: string;
  monthlyContribution?: string;
  savingCategory?: string;
  frequency?: string;
  startDate?: string;
  endDate?: string;
};
interface BeginSavingsTargetProps {
  handleNext: () => void;
  formValues: BeginSavingsProps;
  setFormValues: (data: BeginSavingsProps) => void;
}
function BeginSavingsTarget({
  handleNext,
  formValues,
  setFormValues,
}: BeginSavingsTargetProps) {
  const [dropdownOptions, setDropdownOptions] = useState('');
  const [dropdownSaveOptions, setDropdownSaveOptions] = useState('');
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState<OptionType[]>([]);

  const durationOptions = [
    '3 Months',
    '6 Months',
    '9 Months',
    '12 Months',
    'Customise',
  ];
  const [selectedDuration, setSelectedDuration] = useState<string | null>(null);
  const [customDate, setCustomDate] = useState<Date | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [suggestedAmount, setSuggestedAmount] = useState<number | null>(null);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: yup.object({
      goalName: yup.string().required('Title is required'),
      savingCategory: yup.string().required('Category is required'),
      frequency: yup.string().required('Savings frequency is required'),
      monthlyContribution: yup
        .string()
        .required('Your contribution is required'),
      startDate: yup.date().required('Start date is required'),
      targetAmount: yup.string().required('Amount is required'),
    }),
    onSubmit: (values) => {
      // Prevent default form submission
      // if (handleNext) {
      //   handleNext(values);
      // }
      setFormValues(values);
    },
  });

  useEffect(() => {
    setFormValues(formik.values);
  }, [formik.values]);

  const saveOptions: OptionType[] = [
    {
      label: 'Daily',
      value: 'daily',
    },
    {
      label: 'Weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      value: 'monthly',
    },
  ];

  // const handleDurationClick = (duration: string) => {
  //   setSelectedDuration(duration);
  //   if (duration === 'Customise') {
  //     setShowCalendar(true);
  //   } else {
  //     setShowCalendar(false);
  //     setCustomDate(null);
  //   }
  // };

  // Utility function to format date as "dd/MM/yyyy"
  const formatDate = (date: Date | null) => {
    if (!date) return null;
    return date.toLocaleDateString('en-GB'); // "03/03/2025"
  };

  const handleDurationClick = (duration: string) => {
    setSelectedDuration(duration);
    const startDate = new Date();
    const endDate = new Date();

    if (duration === 'Customise') {
      setShowCalendar(true);
      setCustomDate(null);
      formik.setFieldValue('startDate', formatDate(startDate));
      formik.setFieldValue('endDate', formatDate(endDate));
    } else {
      setShowCalendar(false);
      setCustomDate(null);

      const monthsToAdd = parseInt(duration.split(' ')[0], 10);
      const calculatedEndDate = new Date(startDate);
      calculatedEndDate.setMonth(startDate.getMonth() + monthsToAdd);

      formik.setFieldValue('startDate', formatDate(startDate));
      formik.setFieldValue('endDate', formatDate(calculatedEndDate));
    }
  };

  const handleSelectChange = (selectedOption: OptionType | null) => {
    setDropdownOptions(selectedOption!.value);
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        setLoading(true);
        const { data } = await appAxios.get(`/get-saving-category`);
        if (data && data.statusCode === 200) {
          const formattedOptions = data.data.map((category: any) => ({
            label: category.name,
            value: category.id,
          }));
          setCategoryOptions(formattedOptions);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    getCategories();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number
  ) => {
    const { name, value } = e.target;

    const plainValue = value.replace(/,/g, '');
    if (name === `targetAmount` && index !== undefined) {
      const formattedValue = formatWithCommas(plainValue);
      formik.setFieldValue(`targetAmount`, formattedValue);
      formik.handleChange(e);
    } else if (name === 'monthlyContribution') {
      setAmount(Number(plainValue));
    } else {
      formik.handleChange(e);
    }

    // clearErrors(name);
  };

  const [amount, setAmount] = useState<number | null>(null);

  const handleAmountChange = (value: number) => {
    setAmount(value);
  };
  const formatWithCommas = (value: string) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    return sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    if (amount !== null) {
      formik.setFieldValue('monthlyContribution', amount.toString());
    }
  }, [amount]);

  useEffect(() => {
    if (
      formik.values.startDate &&
      formik.values.frequency &&
      selectedDuration === 'Customise'
    ) {
      const startDate = new Date(formik.values.startDate);
      let endDate = new Date(startDate); // Clone startDate

      const targetAmount = Number(formik.values.targetAmount);
      const contribution = Number(formik.values.monthlyContribution);
      const frequency = formik.values.frequency;

      if (!isNaN(targetAmount) && !isNaN(contribution) && contribution > 0) {
        const totalPeriods = Math.ceil(targetAmount / contribution); // Calculate total periods

        switch (frequency) {
          case 'daily':
            endDate.setDate(startDate.getDate() + totalPeriods);
            break;
          case 'weekly':
            endDate.setDate(startDate.getDate() + totalPeriods * 7);
            break;
          case 'monthly':
            endDate.setMonth(startDate.getMonth() + totalPeriods);
            break;
          default:
            return;
        }

        // Format the date to "YYYY-MM-DD" before setting it
        const formattedEndDate = endDate.toISOString().split('T')[0];

        if (formik.values.endDate !== formattedEndDate) {
          formik.setFieldValue('endDate', formattedEndDate);
        }
      }
    }
  }, [
    formik.values.startDate,
    formik.values.frequency,
    formik.values.targetAmount,
    formik.values.monthlyContribution,
  ]);

  useEffect(() => {
    const calculateSuggestedAmount = () => {
      if (!formik.values.targetAmount || !formik.values.frequency) return;

      const targetAmount = Number(formik.values.targetAmount.replace(/,/g, ''));
      let periods = 1;

      switch (formik.values.frequency) {
        case 'daily':
          periods = 30; // Assume 30 days in a month
          break;
        case 'weekly':
          periods = 4; // Assume 4 weeks in a month
          break;
        case 'monthly':
          periods = 1; // Monthly means one contribution per month
          break;
        default:
          return;
      }

      const calculatedAmount = Math.floor(targetAmount / periods);
      setSuggestedAmount(calculatedAmount);
    };

    calculateSuggestedAmount();
  }, [formik.values.targetAmount, formik.values.frequency]);

  return (
    <section className="">
      <div className="flex flex-col gap-y-[10px] mb-[30px]">
        {/* <Link to="/" className="flex gap-x-2 text-[#EFA005]">
          <ArrowLeft2 /> Back
        </Link> */}
        <div className="flex flex-col lg:flex-row justify-between items-center ">
          <div className="mb-4 lg:mb-0">
            <h1 className="font-semibold">
              Create a JompReserve Target Savings
            </h1>
            <p>
              Set up a new savings target and get paid everyday (@ 12% interest
              P.A) to reach your goals faster
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-x-[50px]">
        <main className=" flex-1 bg-white rounded-md px-[15px] py-[20px] lg:w-[60%]">
          <header className="flex flex-col gap-y-[3px] mb-[30px]">
            <h1 className="font-semibold text-[20px]">Start Savings</h1>
            <div className="flex gap-3 items-center p-2 rounded-md bg-[#F0EDFF] ">
              <IoAlertCircle className="text-[#31005C] w-8 h-8" />
              <p className="text-xs">
                Set up a new savings target and get paid every day (@ 12%
                interest P.A) to reach your goals faster.
              </p>
            </div>

            <div className="h-[1px] bg-gray-3 mt-[5px]"></div>
          </header>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-y-[20px]"
          >
            <div>
              <InputField
                label="Savings Title"
                type="text"
                name="goalName"
                placeholder="e.g Traveling to zanzibar"
                value={formik.values.goalName}
                onChange={formik.handleChange}
                required
                onBlur={formik.handleBlur}
                error={formik.errors.goalName}
              />
            </div>

            <div>
              <InputField
                label="Set Savings Goal"
                type="text"
                name="targetAmount"
                placeholder="e.g 500000"
                value={formatWithCommas(formik.values.targetAmount || '')}
                onChange={handleInputChange}
                required
                onBlur={formik.handleBlur}
                error={formik.errors.targetAmount}
              />
            </div>

            <Input
              label="Preferred amount to save"
              options={[500, 1000, 1500, 5000, 10000]}
              onChange={handleAmountChange}
              required
            />

            <div>
              <label className="block text-sm font-medium text-gray-700">
                How would you prefer to save?
              </label>
              <div className="flex gap-3 mt-2">
                {saveOptions.map((option) => (
                  <Button
                    variant="outlined"
                    key={option.value}
                    onClick={() =>
                      formik.setFieldValue('frequency', option.value)
                    }
                    className={`px-4 py-2 border rounded-md text-[#EFA005] ${
                      formik.values.frequency === option.value
                        ? 'bg-[#EFA0051A] border-[#EFA005] border-2 text-[#EFA005]'
                        : 'transparent border-[#EFA005] '
                    }`}
                  >
                    {option.label}
                  </Button>
                ))}
              </div>
              {formik.errors.frequency && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.frequency}
                </p>
              )}
            </div>
            {/* The suggested amount(which is the amount) should be based on targetAmount, monthlyContribution and frequency */}
            <div className="flex gap-3 items-center p-2 rounded-md bg-[#F0EDFF] ">
              <p className="text-xs">
                Based on your selection above, you can be saving (₦
                {suggestedAmount?.toLocaleString()}) per{' '}
                {formik.values.frequency === 'daily'
                  ? 'day'
                  : formik.values.frequency === 'weekly'
                    ? 'week'
                    : 'month'}
                .
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Set Savings Duration
              </label>
              <div className="flex gap-3 mt-2">
                {durationOptions.map((option) => (
                  <Button
                    key={option}
                    variant="outlined"
                    onClick={() => handleDurationClick(option)}
                    className={`p-2 border rounded-md text-[#EFA005] ${
                      selectedDuration === option
                        ? 'bg-[#EFA0051A] border-[#EFA005] border-2 text-[#EFA005]'
                        : 'transparent border-[#EFA005]'
                    }`}
                  >
                    {option}
                  </Button>
                ))}
              </div>

              {/* Show calendar if "Customise" is selected */}
              {showCalendar && (
                // <div className="mt-3">
                //   <label className="block text-md font-medium text-gray-700">
                //     Select a custom date
                //   </label>
                //   <DatePicker
                //     selected={customDate}
                //     // onChange={(
                //     //   date: Date | null,
                //     //   event?: React.SyntheticEvent<any>
                //     // ) => {
                //     //   setCustomDate(date);
                //     // }}
                //     onChange={(date: Date | null) => {
                //       setCustomDate(date);
                //       formik.setFieldValue('startDate', formatDate(date));
                //     }}
                //     // onChange={(date: Date) => setCustomDate(date)}
                //     className="border-[1px] border-gray-300 p-2 rounded-md w-full mt-2"
                //     minDate={new Date()} // Prevent past dates
                //     dateFormat="dd/MM/yyyy"
                //     placeholderText="Select a date"
                //   />
                // </div>
                <div className="flex  gap-y-[20px] mt-3 flex-col lg:flex-row lg:gap-x-[20px]">
                  <div className="w-full flex justify-between px-[10px] py-1 dark:focus:border-[#EFA005] outline-none items-center ">
                    <InputField
                      label="Customise your start date"
                      type="date"
                      name="startDate"
                      placeholder="e.g dd/mm/yy"
                      value={formik.values.startDate}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.startDate}
                    />
                  </div>
                  {/* <div className="flex-1">
                  </div> */}
                  <div className="w-full flex justify-between py-1 dark:focus:border-[#EFA005] outline-none items-center ">
                    <InputField
                      label="Customise your end date"
                      type="date"
                      name="endDate"
                      placeholder="e.g dd/mm/yy"
                      value={formik.values.endDate}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.endDate}
                    />
                  </div>
                  {/* <div className="flex-1">
                  </div> */}
                </div>
              )}
            </div>

            {/* Select Category */}
            <SelectInput
              label="Select a Category"
              name="savingCategory"
              options={categoryOptions}
              placeholder="Select Category..."
              value={
                categoryOptions.find(
                  (option) => option.label === formik.values.savingCategory
                ) || null
              }
              onChange={(name, value) =>
                formik.setFieldValue(name, value?.label)
              }
              required
              error={formik.errors.savingCategory}
            />

            <Button
              type="button"
              onClick={() => handleNext()}
              className="bg-[#EFA005] text-white ml-auto px-12 py-2 text-sm rounded-md"
            >
              Proceed
            </Button>
          </form>
        </main>

        <SavingsSummary formValues={formValues} />
      </div>
    </section>
  );
}

export default BeginSavingsTarget;
