import { MdClose } from 'react-icons/md';
import { useState } from 'react';
import Modal from 'components/shared/modal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputField from 'components/shared/input-field';
import { appAxios } from 'api/axios';
import { getTokenDetails } from 'functions/userSession';
import { decodeToken } from 'react-jwt';
import { TokenType } from 'types/jwt';
import { Loader } from 'lucide-react';
import { formatToNaira } from 'functions/currencyConveter';
import { sendCatchFeedback, sendFeedback } from 'functions/feedback';
import AuthAnimatedModal from 'components/shared/modal/AuthAnimatedModal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
};

const validationSchema = yup.object({
  amount: yup.number().required('Amount is required'),
  passcode: yup.string().required('Passcode is required'),
});

function WithdrawSavingsPopUs({ isOpen, onClose, id }: Props) {
  // const [prompIsOpen, setPrompIsOpen] = useState(true);
  const token = getTokenDetails();
  const decodedToken = decodeToken<TokenType>(token!);
  const customerId = decodedToken?.customerId;
  const [loading, setLoading] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      customerId: customerId,
      amount: '',
      passcode: '',
      savingsGoalId: id,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { customerId, amount, passcode, savingsGoalId } = values;
        const { data } = await appAxios.post(
          `/with-draw-savings?customerId=${customerId}&amount=${amount}&passcode=${passcode}&savingsGoalId=${savingsGoalId}`
        );
        if (data.statusCode === 200) {
          sendFeedback(data?.message, 'success');
          setIsModalSuccessOpen(true);
          // formik.resetForm();
          // onClose();
        } else sendCatchFeedback(data);
      } catch (error) {
        sendCatchFeedback(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleProceed = () => {
    setIsModalSuccessOpen(false);
    onClose();
    window.location.reload();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Withdraw Savings"
      subtitle="Please enter your details to withdraw your savings"
      width="400px"
    >
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <InputField
          name="amount"
          label="Amount to withdraw"
          type="number"
          placeholder="Enter amount to withdraw"
          value={formik.values.amount}
          // onChange={formik.handleChange}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value;
            // Allow only numbers and decimals
            if (/^\d*\.?\d*$/.test(value)) {
              formik.setFieldValue('amount', value);
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.amount ? formik.errors.amount : ''}
          maxLength={19}
        />
        <InputField
          name="passcode"
          label="Passcode"
          placeholder="Enter your 4 digits transaction pin"
          type="password"
          value={formik.values.passcode}
          // onChange={formik.handleChange}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value;
            if (/^\d*$/.test(value) && value.length <= 4) {
              formik.setFieldValue('passcode', value);
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.passcode ? formik.errors.passcode : ''}
        />
        <div className="flex gap-x-4 mt-4 mb-6">
          <button
            type="button"
            className="flex-1 border border-[#EFA005] outline-none rounded-lg text-[#EFA005] p-1"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 border border-[#EFA005] outline-none rounded-lg bg-[#EFA005] text-white p-1"
            disabled={loading}
          >
            {loading ? (
              <Loader className="animate-spin mx-auto" size={16} />
            ) : (
              'Withdraw'
            )}
          </button>
        </div>
      </form>

      {isModalSuccessOpen && (
        <AuthAnimatedModal
          isOpen={isModalSuccessOpen}
          onClose={handleProceed}
          onConfirm={handleProceed}
          title="Withdrawal Successful!"
          btnText="Proceed"
          content={
            <p>
              You have successfully withdraw the sum of{' '}
              <span className="font-semibold">
                {formatToNaira(Number(formik.values.amount))}
              </span>
              . Thank you!
            </p>
          }
        />
      )}
    </Modal>
  );
}

export default WithdrawSavingsPopUs;
