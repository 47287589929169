import React from 'react';
import styles from './styles.module.css';

const Checkbox = ({
  className,
  id,
  label,
  ...rest
}: { className?: string; id: string } & React.HTMLProps<HTMLInputElement>) => {
  return (
    <div className={'flex items-center cursor-pointer ' + className}>
      <input id={id} type="checkbox" className={styles.checkbox} {...rest} />
      {label && (
        <label htmlFor={id} className="ml-[14px] font-medium text-BrandGray800">
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
