import React from 'react';
import { X } from 'lucide-react';
import Button from '../button';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  amount: string;
  status: string;
  details: { label: string; value: string }[];
  primaryAction: { text: string; onClick: () => void };
  secondaryAction?: { text: string; onClick: () => void };
}

const SavingsTabModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  amount,
  status,
  details,
  primaryAction,
  secondaryAction,
}) => {
  if (!isOpen) return null;
  const isRolledOver = title.includes('Rolled-over');

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full overflow-y-auto bg-gray-500 bg-opacity-50 px-5">
      <div className="relative p-6 w-full max-w-md bg-white rounded-lg shadow-lg">
        <button
          type="button"
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-900"
          onClick={onClose}
        >
          <X size={20} />
        </button>

        <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
        <div className="mt-4 bg-gray-100 p-4 rounded-md text-center">
          <p className="text-2xl font-bold text-gray-900">{amount}</p>
          <span className="px-3 py-1 mt-2 inline-block text-sm text-red-600 bg-red-100 rounded-lg">
            {status}
          </span>
        </div>

        <div className="mt-4 space-y-2">
          {details.map((item, index) => (
            <div key={index} className="flex justify-between text-gray-700">
              <span>{item.label}</span>
              <span
                className={`font-semibold ${item.label === 'Interest Earned' ? 'text-green-600' : ''}`}
              >
                {item.value}
              </span>
            </div>
          ))}
        </div>

        <div className="mt-6 flex justify-between">
          {isRolledOver ? (
            <>
              <Button
                onClick={secondaryAction?.onClick}
                className="bg-gray-200 text-gray-700"
              >
                Withdraw
              </Button>
              <Button
                onClick={primaryAction.onClick}
                className="bg-yellow-500 text-white"
              >
                Reinvest with the Time Period
              </Button>
            </>
          ) : (
            <>
              {secondaryAction && (
                <Button
                  onClick={secondaryAction.onClick}
                  className="bg-gray-200 text-gray-700"
                >
                  Cancel
                </Button>
              )}
              <Button
                onClick={primaryAction.onClick}
                className="bg-yellow-500 text-white"
              >
                Withdraw
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavingsTabModal;
